.botoes-opcoes-confirm-request {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.option-cancel-request {
  display: flex;
  margin: 5px;
  margin-top: 10px;
  padding: 15px;
  cursor: pointer;
  color: #d32f2f;
  background-color: white;
  border: solid 1px #d32f2f;
  text-align: left;
  border-radius: 5px;
  width: 100%;

  .icon-arrow {
    color: red;
  }
}
.option-confirm-request {
  display: flex;
  margin: 5px;
  padding: 15px;
  cursor: pointer;
  color: white;
  text-align: left;
  border-radius: 5px;
  width: 100%;
}
